<template>
  <div
    class="ui-select"
    :class="{'--expanded': dropdownIsOpen, '--collapsed': !dropdownIsOpen, '--multiple': multiple, '--single': !multiple, '--disabled': disabled}"
  >
    <!-- elemento nativo -->
    <select
      :multiple="multiple"
      v-model="nativeModel"
    >
      <option :value="null">---</option>
      <option
        v-for="(option, i) in filteredOptions"
        :key="i"
        :value="option.value"
        v-text="option.text"
      ></option>
    </select>

    <!-- abrir un slot invisible para montar los <ui-option> hijos (si los hay) -->
    <div style="display:none">
      <slot name="default"></slot>
    </div>

    <UiPopover
      class="main-dropdown"
      :open.sync="dropdownIsOpen"
      @open="onDropdownOpen()"
      @close="onDropdownClose()"
    >
      <template #trigger>
        <div
          class="ui-row"
          :class="{'--tight':multiple}"
        >
          <ui-item
            v-for="option in selectedOptions"
            :key="option.value"
            :text="option.text"
            :icon="option.icon"
            class="ui-item-selected ui-outset"
            v-show="multiple || !dropdownIsOpen"
          >
            <template #right>
              <ui-icon
                size="18"
                class="ui-select-clear"
                value="g:clear"
                @click.stop="deselectOption(option)"
              ></ui-icon>
            </template>
          </ui-item>

          <ui-item
            class="ui-input-item ui-inset"
            v-show="multiple || dropdownIsOpen || selectedOptions.length == 0"
          >
            <input
              class="ui-select-search-input"
              type="text"
              autocomplete="false"
              :placeholder="dropdownIsOpen ? $t('UiSelect.label.search') : placeholder"
              v-model="searchText"
              @keydown.delete="deselectLast()"
            />

            <template #right>
              <ui-icon
                class="ui-select-arrow"
                value="mdi:chevron-down"
              ></ui-icon>
            </template>
          </ui-item>
        </div>
      </template>

      <template #contents>
        <ui-list
          selectable
          class="ui-select-options-list"
        >
          <ui-item
            v-for="(option, i) in filteredOptions"
            :key="option.value"
            v-bind="option"
            @click="clickOption(option)"
            :class="{'item-highlighted': i == highlightedIndex, 'option-selected': option.__selected}"
          ></ui-item>
        </ui-list>
      </template>
    </UiPopover>
  </div>
</template>

<script>
/*
UiSelect es un reemplazo stand-in de select nativo:

<select>                 -->    <ui-select>
    <option></option>    -->        <ui-option></ui-option>
    ...                  -->        ...
    <optgroup>           -->        <ui-optgroup>  !!!! pendiente implementar optgroup!
        ....             -->            ....
    </optgroup>          -->        </ui-optgroup>
</select>                -->    </ui-select>

pero tambien puede recibir una propiedad "options" con un arreglo de datos,
conteniendo elementos con las mismas propiedades de un ui-item (icon, text, secondary)
y adicionalmente una propiedad "group", mediante la cual se agrupan opciones (similar a un optgroup)
y una propiedad "value"

[
    {
        icon: "g:person",
        text: "Nombre a mostrar",
        secondary: "Texto secundario",
        group: "Ejemplos",
        value: 123
    },
    ...
]
*/

import useI18n from '@/modules/i18n/mixins/useI18n.js';
import { UiPopover, UiIcon, UiList, UiItem } from '@/modules/ui/components';

export default {
  name: 'ui-select',
  mixins: [useI18n],

  components: {
    UiPopover,
    UiIcon,
    UiList,
    UiItem
  },

  provide() {
    return {
      appendOption: this.appendOption
    };
  },

  props: {
    value: {
      required: false,
      default: null
    },

    options: {
      type: Array,
      required: false,
      default: () => []
    },

    multiple: {
      type: Boolean,
      required: false,
      default: false
    },

    placeholder: {
      type: String,
      required: false,
      default: ''
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      innerOptions: [],
      searchText: '',
      highlightedIndex: null,
      dropdownIsOpen: false,

      selectedValues: []
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (this.multiple) {
          if (!Array.isArray(newValue)) {
            //  console.warn("UiSelect:multiple solo puede aceptar Arreglos como v-model");
            return;
          }
          this.selectedValues = newValue.slice(0);
        } else {
          this.selectedValues = [newValue];
        }
      }
    },

    options: {
      immediate: true,
      handler(incomingOptions) {
        this.innerOptions = incomingOptions;
      }
    }
  },

  computed: {
    nativeModel: {
      get() {
        return this.multiple
          ? this.selectedValues
          : this.selectedValues.length
          ? this.selectedValues[0]
          : null;
      },

      set(val) {
        if (this.multiple) {
          this.selectedValues = val;
        } else {
          this.selectedValues = [val];
        }

        this.emitInput();
      }
    },

    selectedOptions() {
      let selectedOptions = [];
      this.selectedValues.forEach(value => {
        let foundOption = this.innerOptions.find(opt => opt.value == value);
        if (!foundOption) {
          if (value === null) {
            return;
          }

          foundOption = {
            value,
            text: value,
            secondary: '?'
          };
        }
        selectedOptions.push(foundOption);
      });

      return selectedOptions;
    },

    keyedOptions() {
      return this.innerOptions.map(option => {
        let fullText = option.text;

        if (typeof option.secondary != 'undefined') {
          fullText += option.secondary;
        }

        if (typeof option.tertiary != 'undefined') {
          fullText += option.tertiary;
        }

        if (typeof option.group != 'undefined') {
          fullText += option.group;
        }

        option._searchKey = fullText
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();
        return option;
      });
    },

    filteredOptions() {
      let searchText = this.searchText
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      let words = searchText.split(' ');

      return this.keyedOptions
        .map(option => {
          option.__selected = this.isSelected(option);
          return option;
        })
        .filter(option => {
          return words.every(word => option._searchKey.includes(word));
        });
    }
  },

  methods: {
    clickOption(option) {
      return !this.isSelected(option)
        ? this.selectOption(option)
        : this.deselectOption(option);
    },

    emitInput() {
      let targetValue = this.multiple
        ? this.selectedValues.slice(0)
        : this.selectedValues[0] || null;

      this.$emit('input', targetValue);
      this.$emit('change', targetValue);
      this.searchText = '';

      if (!this.multiple) {
        this.dropdownIsOpen = false;
      }
    },

    selectOption(option) {
      if (this.isSelected(option)) {
        return;
      }

      if (this.multiple) {
        this.selectedValues.push(option.value);
      } else {
        this.selectedValues = [option.value];
      }

      this.emitInput();
    },

    deselectOption(option) {
      this.selectedValues.splice(this.selectedValues.indexOf(option.value), 1);
      this.emitInput();
    },

    isSelected(option) {
      return this.selectedValues.indexOf(option.value) >= 0;
    },

    appendOption(option) {
      this.innerOptions.push(option);
    },

    onDropdownOpen() {
      this.highlightedIndex = Math.max(
        this.filteredOptions.findIndex(opt => opt.value == this.value),
        0
      );

      let input = this.$el.querySelector('.ui-select-search-input');
      if (input) {
        this.$nextTick(() => input.focus());
      }

      document.addEventListener('keydown', this.keydownListener);
    },

    onDropdownClose() {
      this.highlightedIndex = 0;
      document.removeEventListener('keydown', this.keydownListener);
    },

    deselectLast() {
      if (
        !this.searchText &&
        this.multiple &&
        this.selectedOptions.length >= 1
      ) {
        this.deselectOption(
          this.selectedOptions[this.selectedOptions.length - 1]
        );
      }
    },

    keydownListener(event) {
      let hl;

      switch (event.key) {
        case 'ArrowDown':
          this.highlightedIndex = Math.min(
            this.highlightedIndex + 1,
            this.filteredOptions.length - 1
          );

          // scroll to highlighted
          // this.$nextTick(() => {
          hl = this.$el.querySelector('.item-highlighted');
          if (hl) {
            hl.scrollIntoView();
          }
          // });

          break;

        case 'ArrowUp':
          this.highlightedIndex = Math.max(this.highlightedIndex - 1, 0);

          // scroll to highlighted
          this.$nextTick(() => {
            hl = this.$el.querySelector('.item-highlighted');
            if (hl) {
              hl.scrollIntoView();
            }
          });

          break;

        case 'Enter':
          this.clickOption(this.filteredOptions[this.highlightedIndex]);
          break;

        // case 'Backspace':
        //     this.deselectLast();
        // break;
      }
    }
  },

  i18n: {
    en: {
      "UiSelect.label.search": "Search",
    },

    es: {
      "UiSelect.label.search": "Buscar",
    },

    de: {
      "UiSelect.label.search": "Suche",
    },
  }
};
</script>

<style lang="scss">
// Use native HTML select
.ui-select.--native {
  position: relative;
  select {
    display: block !important;
    overflow: hidden;
    min-width: 90%;
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    outline: none;
  }
  .main-dropdown {
    pointer-events: none;
  }
}

.ui-select-options-list {
  min-width: 300px;
  max-height: 400px;
  overflow: auto;
}

@media (max-width: 800px) {
  .ui-select {
    position: relative;
    select {
      display: block !important;
      overflow: hidden;
      min-width: 90%;
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      outline: none;
    }
    .main-dropdown {
      pointer-events: none;
    }
  }
}

.ui-select {
  display: inline-block;

  select {
    display: none;
  }

  .ui-item-selected {
    cursor: pointer;
    --item-icon-width: 38px;

    .item-body {
      font-size: 15px;
      padding: var(--ui-padding-vertical);
    }

    .item-right {
      padding: var(--ui-padding);
    }
  }

  &.--single {
    .ui-item-selected {
      flex: 1;
    }
  }

  &.--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .ui-input-item {
    flex: 1;
    min-width: 120px;
  }

  .ui-select-search-input {
    width: 100%;
    border-radius: var(--ui-radius);
    padding: var(--ui-padding);

    display: block;
    font-size: inherit;
    border: 0;
    outline: none;
    background: transparent;
  }

  .option-selected {
    opacity: 0.9;
    color: var(--ui-color-primary);
    border-left: 2px solid var(--ui-color-primary);
  }

  .item-highlighted {
    background-color: var(--ui-color-highlight);
  }
}
</style>